import router from "@/router";
import { moduleActionContext } from "@/store/index";
import { AxiosError, AxiosResponse } from "axios";
import { defineModule } from "direct-vuex";
import { saveFile } from "../../assets/file-saver";
import i18n from "../../assets/i18n";
import { ContractorTypes } from "../../models/emums/contractorTypes";
import ListItemNumberModel from "../../models/listItemNumberModel";
import ListItemStringModel from "../../models/listItemStringModel";
import ContractorAssetsRestModel from "../../models/rest/contractor/contractorAssetsRestModel";
import ContractorBankAccountRestModel from "../../models/rest/contractor/contractorBankAccountRestModel";
import ContractorContactPersonRestModel from "../../models/rest/contractor/contractorContactPersonRestModel";
import ContractorDetailsForSettlementRestModel from "../../models/rest/contractor/contractorDetailsForSettlementRestModel";
import ContractorDetailsForSupervisorRestModel from "../../models/rest/contractor/contractorDetailsForSupervisorRestModel";
import ContractorDocumentRestModel from "../../models/rest/contractor/contractorDocumentRestModel";
import ContractorListRequestModel from "../../models/rest/contractor/contractorListRequestModel";
import ContractorLocationRestModel from "../../models/rest/contractor/contractorLocationRestModel";
import ContractorMementoItemRestModel from "../../models/rest/contractor/contractorMementoItemRestModel";
import ContractorNoteRestModel from "../../models/rest/contractor/contractorNoteRestModel";
import ContractorOperationRestModel from "../../models/rest/contractor/contractorOperationRestModel";
import ContractorPolicyRestModel from "../../models/rest/contractor/contractorPolicyRestModel";
import ContractorRestModel from "../../models/rest/contractor/contractorRestModel";
import { axiosInstance } from "../../plugins/axios";

export interface ContractorModuleStateInterface {
    allowContractorEdit: boolean;
    isContractorNew: boolean;
    contractor: ContractorRestModel;
    contractorAssets: ContractorAssetsRestModel;
    contractorAssetsLoaded: boolean;
    contractorList: ContractorRestModel[];
    contractorListLoading: false | boolean;
    contractorListTotalCount: number;
    contractorListPagerOptions: any;
    contractorListSearchText: string;
    contractorListSearchId: number | undefined;
    contractorListSearchContractorTypeList: string[];
    contractorListSearchContractorStatusList: string[];
    contractorListSearchAccountManagerIdList: any;
    contractorListSearchCountryCode: string[],
    contractorImportList: ContractorRestModel[];
    contractorImportLoading: false | boolean;
    contractorImportShowDialog: false | boolean;
    contractorTypeList: any[];
    operation: ContractorOperationRestModel;
    operationList: ContractorOperationRestModel[];
    operationTagSearch: ListItemNumberModel[];
    operationSearchOperationBySelectedTags: number[];
    contactPerson: ContractorContactPersonRestModel;
    contactPersonList: ContractorContactPersonRestModel[];
    emailAddressList: ListItemNumberModel[];
    emailAddress: ListItemNumberModel;
    showContactPersonDialog: false | boolean;
    showEmailAddressDialog: false | boolean;
    bankAccount: ContractorBankAccountRestModel;
    bankAccountList: ContractorBankAccountRestModel[];
    showBankAccountDialog: false | boolean;
    bankAccountValidationError: any
    bankAccountPurposeTypeList: ListItemNumberModel[];
    contractorStatusList: ListItemNumberModel[];
    documentList: ContractorDocumentRestModel[];
    showOperationsDialog: false | boolean;
    showDocumentsDialog: false | boolean;
    showPolicyDialog: boolean;
    showKpiGeneratingDialog: boolean;
    policy: ContractorPolicyRestModel;
    policyDocumentList: ContractorDocumentRestModel[];
    policyList: ContractorPolicyRestModel[];
    policyRegions: ListItemNumberModel[];
    policyTypes: ListItemStringModel[];
    currentDocumentId: any;
    contractorSearch: ContractorRestModel[];
    contractorsLocationList: ContractorLocationRestModel[];
    contractorsLocationDetails: ContractorLocationRestModel;
    contractorSenderDetailsForSupervisor: ContractorDetailsForSupervisorRestModel;
    contractorCarrierDetailsForSupervisor: ContractorDetailsForSupervisorRestModel;
    contractorPayerDetailsForSupervisor: ContractorDetailsForSupervisorRestModel;
    contractorDetailsForSettlement: ContractorDetailsForSettlementRestModel;
    checkIfContractorPolicyIsActive: boolean;
    checkIfContractorClientHasExceededDebtLimit: boolean;
    checkIfContractorPayerHasExceededDebtLimit: boolean;
    note: ContractorNoteRestModel;
    noteList: ContractorNoteRestModel[];
    showNoteDialog: boolean;
    contractorMementoListCount: number;
    contractorMementoList: ContractorMementoItemRestModel[];
    isContractorInHistoricalState: boolean;
    selectedContractorCarrierTagListForFilter: ListItemNumberModel[];
}

const contractorModule = defineModule({
    namespaced: true,
    state: {
        allowContractorEdit: false,
        isContractorNew: false,
        contractor: {} as ContractorRestModel,
        contractorAssets: {} as ContractorAssetsRestModel,
        contractorAssetsLoaded: false,
        contractorList: [] as ContractorRestModel[],
        contractorListLoading: false,
        contractorListTotalCount: 0,
        contractorListPagerOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ["name"],
            sortDesc: [false]
        },
        contractorListSearchText: "",
        contractorListSearchId: undefined,
        contractorListSearchContractorTypeList: [] as string[],
        contractorListSearchContractorStatusList: [] as string[],
        contractorListSearchAccountManagerIdList: [],
        contractorListSearchCountryCode: [] as string[],
        contractorImportList: [] as ContractorRestModel[],
        contractorImportLoading: false,
        contractorImportShowDialog: false,
        contractorTypeList: [
            { text: "Przewoźnik", value: ContractorTypes.Carrier },
            { text: "Inny", value: ContractorTypes.Other },
            { text: "Partner", value: ContractorTypes.Partner },
            { text: "Zleceniodawca", value: ContractorTypes.Client }
        ],
        operation: {},
        operationList: [] as ContractorOperationRestModel[],
        operationTagSearch: [] as ListItemNumberModel[],
        operationSearchOperationBySelectedTags: [] as number[],
        contactPerson: {},
        contactPersonList: [] as ContractorContactPersonRestModel[],
        emailAddressList: [] as ListItemNumberModel[],
        emailAddress: {} as ListItemNumberModel,
        showContactPersonDialog: false,
        showEmailAddressDialog: false,
        bankAccount: {},
        bankAccountList: [] as ContractorBankAccountRestModel[],
        showBankAccountDialog: false,
        bankAccountValidationError: {} as any,
        bankAccountPurposeTypeList: [
            { text: i18n.t("contractor.generalBankAccount"), value: 0 },
            { text: i18n.t("contractor.costBankAccount"), value: 1 },
            { text: i18n.t("contractor.incomeBankAccount"), value: 2 },
        ],
        contractorStatusList: [
            { text: i18n.t("contractor.enabled"), value: 1 },
            { text: i18n.t("contractor.disabled"), value: 2 },
            { text: i18n.t("contractor.isLockedAsClient"), value: 3 },
            { text: i18n.t("contractor.isLockedAsCarrier"), value: 4 },
        ] as ListItemNumberModel[],
        documentList: [] as ContractorDocumentRestModel[],
        showOperationsDialog: false,
        showDocumentsDialog: false,
        showPolicyDialog: false,
        showKpiGeneratingDialog: false,
        currentDocumentId: null,
        contractorSearch: [] as ContractorRestModel[],
        policy: {} as ContractorPolicyRestModel,
        policyDocumentList: [] as ContractorDocumentRestModel[],
        policyList: [] as ContractorPolicyRestModel[],
        policyRegions: [
            { text: "National", value: 0 },
            { text: "International", value: 1 },
            { text: "Cabotage", value: 2 },
        ] as ListItemNumberModel[],
        policyTypes: [
            { text: "OCP", value: "OCP" },
        ] as ListItemStringModel[],
        contractorsLocationList: [] as ContractorLocationRestModel[],
        contractorsLocationDetails: {} as ContractorLocationRestModel,
        contractorSenderDetailsForSupervisor: {} as ContractorDetailsForSupervisorRestModel | undefined,
        contractorCarrierDetailsForSupervisor: {} as ContractorDetailsForSupervisorRestModel | undefined,
        contractorPayerDetailsForSupervisor: {} as ContractorDetailsForSupervisorRestModel | undefined,
        contractorDetailsForSettlement: {} as ContractorDetailsForSettlementRestModel | undefined,
        checkIfContractorPolicyIsActive: false,
        checkIfContractorClientHasExceededDebtLimit: false,
        checkIfContractorPayerHasExceededDebtLimit: false,
        note: {} as ContractorNoteRestModel,
        noteList: [] as ContractorNoteRestModel[],
        showNoteDialog: false,
        contractorMementoListCount: 0,
        contractorMementoList: [] as ContractorMementoItemRestModel[],
        isContractorInHistoricalState: false,
        selectedContractorCarrierTagListForFilter: [] as ListItemNumberModel[],
    } as ContractorModuleStateInterface,
    mutations: {
        setAllowContractorEdit(state, value: boolean) {
            state.allowContractorEdit = value;
        },
        setIsContractorNew(state, value: boolean) {
            state.isContractorNew = value;
        },
        setContractor(state, value: ContractorRestModel) {
            state.contractor = value;
        },
        setContractorAssets(state, value: ContractorAssetsRestModel) {
            state.contractorAssets = value;
            state.contractorAssetsLoaded = true;
        },
        setContractorAssetsLoaded(state, value: boolean) {
            state.contractorAssetsLoaded = value;
        },
        setContractorList(state, value: ContractorRestModel[]) {
            state.contractorList = value;
        },
        setContractorListLoading(state, value: boolean) {
            state.contractorListLoading = value;
        },
        setContractorListTotalCount(state, value: number) {
            state.contractorListTotalCount = value;
            if ((state.contractorListPagerOptions.page * state.contractorListPagerOptions.itemsPerPage) >= (value + state.contractorListPagerOptions.itemsPerPage)) {
                state.contractorListPagerOptions.page = 1;
            }
        },
        setContractorListPagerOptions(state, requestModel) {
            state.contractorListPagerOptions.sortBy = requestModel.sortBy;
            state.contractorListPagerOptions.sortDesc = requestModel.sortDesc;
            state.contractorListPagerOptions.page = requestModel.page;
            state.contractorListPagerOptions.itemsPerPage = requestModel.itemsPerPage;
        },
        setContractorListSearchText(state, value: string) {
            state.contractorListSearchText = value;
        },
        setContractorListSearchId(state, value: number | undefined) {
            state.contractorListSearchId = value;
        },
        setContractorListSearchContractorTypeList(state, value: string[]) {
            state.contractorListSearchContractorTypeList = value;
        },
        setContractorListSearchContractorStatusList(state, value: string[]) {
            state.contractorListSearchContractorStatusList = value;
        },
        setContractorListSearchAccountManagerIdList(state, value: string[]) {
            state.contractorListSearchAccountManagerIdList = value;
        },
        setContractorListSearchCountryCode(state, value: string[]) {
            state.contractorListSearchCountryCode = value;
        },
        setContractorImportList(state, value: ContractorRestModel[]) {
            state.contractorImportList = value;
        },
        setContractorImportListLoading(state, value: boolean) {
            state.contractorImportLoading = value;
        },
        setContractorImportShowDialog(state, value: boolean) {
            state.contractorImportShowDialog = value;
        },
        setShowOperationsDialog(state, value: boolean) {
            state.showOperationsDialog = value;
        },
        setOperation(state, value: ContractorOperationRestModel) {
            state.operation = value;
        },
        setOperationList(state, value: ContractorOperationRestModel[]) {
            state.operationList = value;
        },
        setOperationTagSearch(state, value: ListItemNumberModel[]) {
            state.operationTagSearch = value;
        },
        setOperationSearchOperationBySelectedTags(state, value: number[]) {
            state.operationSearchOperationBySelectedTags = value;
        },
        setShowContactPersonDialog(state, value: boolean) {
            state.showContactPersonDialog = value;
        },
        setContactPerson(state, value: ContractorContactPersonRestModel) {
            state.contactPerson = value;
        },
        setContactPersonList(state, value: ContractorContactPersonRestModel[]) {
            state.contactPersonList = value;
        },
        setShowEmailAddressDialog(state, value: boolean) {
            state.showEmailAddressDialog = value;
        },
        setEmailAddressList(state, value: ListItemNumberModel[]) {
            state.emailAddressList = value;
        },
        setEmailAddress(state, value: ListItemNumberModel) {
            state.emailAddress = value;
        },
        setShowBankAccountDialog(state, value: boolean) {
            state.showBankAccountDialog = value;
        },
        setBankAccount(state, value: ContractorBankAccountRestModel) {
            state.bankAccount = value;
        },
        setBankAccountList(state, value: ContractorBankAccountRestModel[]) {
            state.bankAccountList = value;
        },
        setBankAccountValidationError(state, value: any) {
            state.bankAccountValidationError = value;
        },
        setPolicy(state, value: ContractorPolicyRestModel) {
            state.policy = value;
        },
        setPolicyDocumentList(state, value: ContractorDocumentRestModel[]) {
            state.policyDocumentList = value;
        },
        setPolicyList(state, value: ContractorPolicyRestModel[]) {
            state.policyList = value;
        },
        setShowDocumentsDialog(state, value: boolean) {
            state.showDocumentsDialog = value;
        },
        setShowPolicyDialog(state, value: boolean) {
            state.showPolicyDialog = value;
        },
        setShowKpiGeneratingDialog(state, value: boolean) {
            state.showKpiGeneratingDialog = value;
        },
        setDocumentList(state, value: ContractorDocumentRestModel[]) {
            state.documentList = value;
        },
        setCurrentDocumentId(state, value: number) {
            state.currentDocumentId = value;
        },
        setContractorSearch(state, value: ContractorRestModel[]) {
            state.contractorSearch = value;
        },
        setContractorsLocationList(state, value: ContractorLocationRestModel[]) {
            state.contractorsLocationList = value;
        },
        setContractorsLocationDetails(state, value: ContractorLocationRestModel) {
            state.contractorsLocationDetails = value;
        },
        setContractorSenderDetailsForSupervisor(state, value: ContractorDetailsForSupervisorRestModel) {
            state.contractorSenderDetailsForSupervisor = value;
        },
        setContractorCarrierDetailsForSupervisor(state, value: ContractorDetailsForSupervisorRestModel) {
            state.contractorCarrierDetailsForSupervisor = value;
        },
        setContractorPayerDetailsForSupervisor(state, value: ContractorDetailsForSupervisorRestModel) {
            state.contractorPayerDetailsForSupervisor = value;
        },
        setContractorDetailsForSettlement(state, value: ContractorDetailsForSettlementRestModel) {
            state.contractorDetailsForSettlement = value;
        },
        setCheckIfContractorPolicyIsActive(state, value: boolean) {
            state.checkIfContractorPolicyIsActive = value;
        },
        setCheckIfContractorClientHasExceededDebtLimit(state, value: boolean) {
            state.checkIfContractorClientHasExceededDebtLimit = value;
        },
        setCheckIfContractorPayerHasExceededDebtLimit(state, value: boolean) {
            state.checkIfContractorPayerHasExceededDebtLimit = value;
        },
        setNote(state, value: ContractorNoteRestModel) {
            state.note = value;
        },
        setNoteList(state, value: ContractorNoteRestModel[]) {
            state.noteList = value;
        },
        setShowNoteDialog(state, value: boolean) {
            state.showNoteDialog = value;
        },
        setContractorMementoListCount(state, value: number) {
            state.contractorMementoListCount = value;
        },
        setContractorMementoList(state, value: ContractorMementoItemRestModel[]) {
            state.contractorMementoList = value;
        },
        setIsContractorInHistoricalState(state, value: boolean) {
            state.isContractorInHistoricalState = value;
        },
        setSelectedContractorCarrierTagListForFilter(state, value: ListItemNumberModel[]) {
            state.selectedContractorCarrierTagListForFilter = value;
        },
    },
    actions: {
        loadContractor(context, payload: { id?: string, types?: string[] }) {
            const { commit, dispatch, rootDispatch } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                const params = {
                    contractorTypes: payload.types,
                };

                if (payload.id != undefined && !isNaN(Number.parseInt(payload.id))) {
                    axiosInstance
                        .get("/api/contractor/" + payload.id)
                        .then((resp: AxiosResponse) => {
                            commit.setContractor(resp.data);
                            dispatch.loadContractorAssets();
                            rootDispatch.settlementCommon.loadSettlementAssets();
                            resolve(resp.data);
                        })
                        .catch((err: AxiosError) => reject(err));
                } else {
                    axiosInstance
                        .get("/api/contractor/temporary", { params })
                        .then((resp: AxiosResponse) => {
                            commit.setContractor(resp.data);
                            dispatch.loadContractorAssets();
                            resolve(resp.data);
                        })
                        .catch((err: AxiosError) => reject(err));
                }
            });
        },
        loadContractorAssets(context) {
            const { commit, state } = _moduleActionContext(context);
            if (state.contractorAssetsLoaded) {
                return new Promise(resolve => {
                    resolve(state.contractorAssets);
                });
            }
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/contractor/assets")
                    .then((resp: AxiosResponse) => {
                        commit.setContractorAssets(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        loadContractorList(context, payload: { requestModel: ContractorListRequestModel }) {
            const { commit, dispatch } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                commit.setContractorImportListLoading(true);
                commit.setContractorListPagerOptions(payload.requestModel);
                axiosInstance
                    .post("/api/contractor/list", payload.requestModel)
                    .then((resp: AxiosResponse) => {
                        commit.setContractorList(resp.data.items);
                        commit.setContractorListTotalCount(resp.data.totalItemsCount);
                        dispatch.loadContractorAssets();
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setContractorImportListLoading(false);
                    });
            });
        },
        contractorListCleanFilters(context) {
            const { commit } = _moduleActionContext(context);
            const filterOptions = {
                searchText: "",
                searchId: undefined,
                searchContractorTypeList: [],
                searchAccountManagerIdList: [],
                page: 1,
                sortBy: ["name"],
                sortDesc: [false],
                itemsPerPage: 10
            }

            commit.setContractorListPagerOptions(filterOptions);
            commit.setContractorListSearchText(filterOptions.searchText);
            commit.setContractorListSearchId(filterOptions.searchId);
            commit.setContractorListSearchContractorTypeList(filterOptions.searchContractorTypeList);
            commit.setContractorListSearchAccountManagerIdList(filterOptions.searchAccountManagerIdList);
        },
        saveContractor(context) {
            const { state, commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                const contractorRestModel = state.contractor;
                axiosInstance
                    .post("/api/contractor/save", contractorRestModel)
                    .then((resp: AxiosResponse) => {
                        commit.setContractor(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        importContractor(context) {
            const { state, commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                commit.setContractorImportListLoading(true);
                axiosInstance
                    .get("/api/contractor/import/" + state.contractor.tin)
                    .then((resp: AxiosResponse) => {
                        commit.setContractorImportList(resp.data);
                        commit.setContractorImportShowDialog(true);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => {
                        reject(err);
                    })
                    .finally(() => commit.setContractorImportListLoading(false));
            });
        },
        updateContractorFromImport(context, payload?: { contractorImport: ContractorRestModel, importFields: string[] }) {
            const { state, commit, dispatch } = _moduleActionContext(context);
            return new Promise(resolve => {
                const importedContractor = payload!.contractorImport;
                const currentContracor = state.contractor;
                for (const importField of payload!.importFields) {
                    switch (importField) {
                        case "symbol":
                            currentContracor.symbol = importedContractor.symbol;
                            break;

                        case "name":
                            currentContracor.name = importedContractor.name;
                            break;

                        case "streetAndNumber":
                            currentContracor.streetAndNumber = importedContractor.streetAndNumber;
                            break;

                        case "country":
                            currentContracor.country = importedContractor.country;
                            break;

                        case "postalCode":
                            currentContracor.postalCode = importedContractor.postalCode;
                            break;

                        case "city":
                            currentContracor.city = importedContractor.city;
                            break;

                        case "tinPrefix":
                            currentContracor.tinPrefix = importedContractor.tinPrefix;
                            break;

                        case "tin":
                            currentContracor.tin = importedContractor.tin;
                            break;

                        case "statisticalNumber":
                            currentContracor.statisticalNumber = importedContractor.statisticalNumber;
                            break;

                        case "paymentTermSale":
                            currentContracor.paymentTermSale = importedContractor.paymentTermSale;
                            break;

                        case "paymentTermPurchase":
                            currentContracor.paymentTermPurchase = importedContractor.paymentTermPurchase;
                            break;

                        case "contractorType":
                            currentContracor.typeIsCarrier = importedContractor.typeIsCarrier;
                            currentContracor.typeIsClient = importedContractor.typeIsClient;
                            currentContracor.typeIsOther = importedContractor.typeIsOther;
                            currentContracor.typeIsPartner = importedContractor.typeIsPartner;
                            break;

                        case "isLockedAsClient":
                            currentContracor.isLockedAsClient = importedContractor.isLockedAsClient;
                            break;

                        case "ocp":
                            axiosInstance
                                .post("/api/contractor/" + state.contractor.id + "/policy/update/" + importedContractor.interlanId)
                                .then(() => {
                                    dispatch.loadPolicyList();
                                });
                            break;
                    }
                }
                if (payload!.importFields.length > 0) {
                    currentContracor.interlanId = importedContractor.interlanId;
                    currentContracor.importDone = true;
                }

                commit.setContractor(currentContracor);
                commit.setContractorImportShowDialog(false);

                resolve(currentContracor);
            });
        },
        closeContactImportDialog(context) {
            const { commit } = _moduleActionContext(context);
            commit.setContractorImportShowDialog(false);
        },
        loadOperation(context, payload?: { id: number }) {
            const { state, commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                if (payload != null) {
                    axiosInstance
                        .get("/api/contractor/" + state.contractor.id + "/operation/" + payload.id)
                        .then((resp: AxiosResponse) => {
                            commit.setOperation(resp.data);
                            commit.setShowOperationsDialog(true);
                            resolve(resp.data);
                        })
                        .catch((err: AxiosError) => reject(err));
                } else {
                    axiosInstance
                        .get("/api/contractor/" + state.contractor.id + "/operation/temporary")
                        .then((resp: AxiosResponse) => {
                            commit.setOperation(resp.data);
                            commit.setShowOperationsDialog(true);
                            resolve(resp.data);
                        })
                        .catch((err: AxiosError) => reject(err));
                }
            });
        },
        closeOperationDialog(context) {
            const { commit } = _moduleActionContext(context);
            commit.setShowOperationsDialog(false);
        },
        saveOperation(context) {
            const { state, commit, dispatch } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/contractor/" + state.contractor.id + "/operation/save", { operation: state.operation, contractor: state.contractor })
                    .then((resp: AxiosResponse) => {
                        commit.setShowOperationsDialog(false);
                        dispatch.loadOperationList();
                        commit.setOperationSearchOperationBySelectedTags([]);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        loadOperationList(context, payload?: { searchTagIdcsArray: number[] }) {
            const { state, commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                const params = {
                    searchTagIdcsArray: payload?.searchTagIdcsArray
                }
                axiosInstance
                    .get("/api/contractor/" + state.contractor.id + "/operation/list", { params })
                    .then((resp: AxiosResponse) => {
                        commit.setOperationList(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        savePolicy(context) {
            const { state, commit, dispatch } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/contractor/" + state.contractor.id + "/policy/save", state.policy)
                    .then((resp: AxiosResponse) => {
                        commit.setPolicy(resp.data);
                        dispatch.loadPolicyList()
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        loadPolicy(context, payload?: { id: number }) {
            const { state, commit, dispatch } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                if (payload != null) {
                    axiosInstance
                        .get("/api/contractor/" + state.contractor.id + "/policy/" + payload.id)
                        .then((resp: AxiosResponse) => {
                            commit.setPolicy(resp.data);
                            dispatch.loadPolicyDocumentList();
                            resolve(resp.data);
                        })
                        .catch((err: AxiosError) => reject(err));
                } else {
                    axiosInstance
                        .get("/api/contractor/" + state.contractor.id + "/policy/temporary")
                        .then((resp: AxiosResponse) => {
                            resp.data.region = undefined;
                            resp.data.value = "";
                            resp.data.policyTerm = undefined;
                            commit.setPolicy(resp.data);
                            resolve(resp.data);
                        })
                        .catch((err: AxiosError) => reject(err));
                }
            });
        },
        loadPolicyDocumentList(context) {
            const { state, commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/contractor/" + state.contractor.id + "/policy/" + state.policy.id + "/document/list")
                    .then((resp: AxiosResponse) => {
                        commit.setPolicyDocumentList(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        loadPolicyList(context) {
            const { state, commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/contractor/" + state.contractor.id + "/policy/list")
                    .then((resp: AxiosResponse) => {
                        commit.setPolicyList(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        savePolicyDocument(context, payload) {
            const { state, commit, dispatch } = _moduleActionContext(context);
            const formData = new FormData();

            for (const index in payload) {
                formData.append("files", payload[index]);
            }

            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/contractor/" + state.contractor.id + "/policy/" + state.policy.id + "/document/save", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    })
                    .then((resp: AxiosResponse) => {
                        commit.setShowDocumentsDialog(false);
                        dispatch.loadPolicyList();
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        downloadPolicyDocument(context, payload: { id: number }) {
            const { state } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/contractor/" + state.contractor.id + "/policy/" + state.policy.id + "/document/download/" + payload.id, {
                        responseType: "arraybuffer"
                    })
                    .then((resp: AxiosResponse) => {
                        const blob = new Blob([resp.data], {
                            type: resp.headers["content-type"]
                        });
                        const contentDisposition = resp.headers["content-disposition"];
                        const filename = contentDisposition
                            .split(";")[1]
                            .split("=")[1]
                            .replace('"', "")
                            .replace('"', "");

                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement("a");
                        a.style.display = "none";
                        document.body.appendChild(a);
                        a.download = filename;
                        a.href = url;
                        a.click();
                        window.URL.revokeObjectURL(url);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        deletePolicyDocument(context, payload: { id: number }) {
            const { state, dispatch } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/contractor/" + state.contractor.id + "/policy/" + state.policy.id + "/document/delete/" + payload.id)
                    .then((resp: AxiosResponse) => {
                        dispatch.loadPolicyDocumentList();
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        searchTags(context, payload: string) {
            const { state, commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/contractor/operation/tags", { searchText: payload })
                    .then((resp: AxiosResponse) => {
                        commit.setOperationTagSearch(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        loadContactPerson(context, payload?: { id: number }) {
            const { state, commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                if (payload != null) {
                    axiosInstance
                        .get("/api/contractor/" + state.contractor.id + "/contactPerson/" + payload.id)
                        .then((resp: AxiosResponse) => {
                            commit.setContactPerson(resp.data);
                            commit.setShowContactPersonDialog(true);
                            resolve(resp.data);
                        })
                        .catch((err: AxiosError) => reject(err));
                } else {
                    axiosInstance
                        .get("/api/contractor/" + state.contractor.id + "/contactPerson/temporary")
                        .then((resp: AxiosResponse) => {
                            commit.setContactPerson(resp.data);
                            commit.setShowContactPersonDialog(true);
                            resolve(resp.data);
                        })
                        .catch((err: AxiosError) => reject(err));
                }
            });
        },
        closeContactPersonDialog(context) {
            const { commit } = _moduleActionContext(context);
            commit.setShowContactPersonDialog(false);
        },
        saveContactPerson(context, payload?: { loadContactPersonList: boolean }) {
            const { state, commit, dispatch } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/contractor/" + state.contractor.id + "/contactPerson/save", state.contactPerson)
                    .then((resp: AxiosResponse) => {
                        commit.setShowContactPersonDialog(false);
                        if (payload == undefined) {
                            dispatch.loadContactPersonList();
                        }
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        loadContactPersonList(context) {
            const { state, commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/contractor/" + state.contractor.id + "/contactPerson/list")
                    .then((resp: AxiosResponse) => {
                        commit.setContactPersonList(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        loadEmailAddress(context, payload: { id: number }) {
            const { state, commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/contractor/" + state.contractor.id + "/emailAddress/" + payload.id)
                    .then((resp: AxiosResponse) => {
                        commit.setEmailAddress(resp.data);
                        commit.setShowEmailAddressDialog(true);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        loadEmailAddressList(context, payload?: { contractorId: number | null }) {
            const { state, commit } = _moduleActionContext(context);
            const contractorId = (payload == undefined || payload.contractorId == null) ? state.contractor.id : payload.contractorId
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/contractor/" + contractorId + "/emailAddress/list")
                    .then((resp: AxiosResponse) => {
                        commit.setEmailAddressList(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        closeEmailAddressDialog(context) {
            const { commit } = _moduleActionContext(context);
            commit.setShowEmailAddressDialog(false);
        },
        saveEmailAddress(context) {
            const { state, commit, dispatch } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/contractor/" + state.contractor.id + "/emailAddress/save", state.emailAddress)
                    .then((resp: AxiosResponse) => {
                        commit.setShowEmailAddressDialog(false);
                        dispatch.loadEmailAddressList();
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        deleteEmailAddress(context, payload: { id: number }) {
            const { state, dispatch } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/contractor/" + state.contractor.id + "/emailAddress/delete/" + payload.id)
                    .then((resp: AxiosResponse) => {
                        dispatch.loadEmailAddressList();
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        loadBankAccount(context, payload: { id: number }) {
            const { state, commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/contractor/" + state.contractor.id + "/bankAccount/" + payload.id)
                    .then((resp: AxiosResponse) => {
                        commit.setBankAccount(resp.data);
                        commit.setShowBankAccountDialog(true);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        closeBankAccountDialog(context) {
            const { commit } = _moduleActionContext(context);
            commit.setShowBankAccountDialog(false);
        },
        saveBankAccount(context) {
            const { state, commit, dispatch } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/contractor/" + state.contractor.id + "/bankAccount/save", state.bankAccount)
                    .then((resp: AxiosResponse) => {
                        commit.setBankAccountValidationError({});
                        commit.setShowBankAccountDialog(false);
                        dispatch.loadContractorBankAccountList();
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => {
                        if (err.response && err.response.data.errors) {
                            commit.setBankAccountValidationError(err.response.data.errors);
                            resolve(err.response.data.errors)
                        }
                        reject(err)
                    });
            });
        },
        async loadContractorBankAccountList(context, payload?: { contractorId: number }) {
            const { state, commit } = _moduleActionContext(context);
            const contractorId = payload != undefined ? payload.contractorId : state.contractor.id;
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/contractor/" + contractorId + "/bankAccount/list")
                    .then(async (resp: AxiosResponse) => {
                        commit.setBankAccountList(resp.data);
                        await resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        loadDocument(context) {
            const { commit } = _moduleActionContext(context);
            commit.setShowDocumentsDialog(true);
        },
        closeDocumentDialog(context) {
            const { commit } = _moduleActionContext(context);
            commit.setShowDocumentsDialog(false);
        },
        saveDocument(context, payload) {
            const { state, commit, dispatch } = _moduleActionContext(context);
            const formData = new FormData();

            for (const index in payload) {
                formData.append("files", payload[index]);
            }

            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/contractor/" + state.contractor.id + "/document/save", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    })
                    .then((resp: AxiosResponse) => {
                        commit.setShowDocumentsDialog(false);
                        dispatch.loadContractorDocumentList();
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        loadContractorDocumentList(context) {
            const { state, commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/contractor/" + state.contractor.id + "/document/list")
                    .then((resp: AxiosResponse) => {
                        commit.setDocumentList(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        downloadDocument(context, payload?: { id: number }) {
            const { state, dispatch, rootDispatch } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/contractor/" + state.contractor.id + "/document/download/" + payload?.id, {
                        responseType: "arraybuffer"
                    })
                    .then((resp: AxiosResponse) => {
                        if (resp.status == 204) {
                            rootDispatch.confirm.openConfirmDialog({
                                showConfirmDialog: true,
                                title: i18n.t("common.fileDoesNotExist").toString(),
                                message: i18n.t("common.fileDoesNotExist").toString(),
                                links: [],
                                options: { buttonColor: "error", layoutColor: "error" },
                                buttonType: "ok"
                            })
                            router.push({ path: "/contractor/edit/" + state.contractor.id })
                        }
                        else {
                            const blob = new Blob([resp.data], {
                                type: resp.headers["content-type"]
                            });
                            const contentDisposition = resp.headers["content-disposition"];
                            const filename = contentDisposition
                                .split(";")[1]
                                .split("=")[1]
                                .replace('"', "")
                                .replace('"', "");

                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement("a");
                            a.style.display = "none";
                            document.body.appendChild(a);
                            a.download = filename;
                            a.href = url;
                            a.click();
                            window.URL.revokeObjectURL(url);
                        }
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => {
                        reject(err)
                    });
            });
        },
        deleteDocumentRequest(context, payload: { id: any }) {
            const { state, dispatch } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/contractor/" + state.contractor.id + "/document/delete/" + payload.id)
                    .then((resp: AxiosResponse) => {
                        dispatch.loadContractorDocumentList();
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        deleteDocumentAccept(context, payload: { id: any }) {
            const { state, dispatch, rootDispatch } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/contractor/" + state.contractor.id + "/document/delete/" + payload.id + "/accepted")
                    .then((resp: AxiosResponse) => {
                        if (resp.status == 204) {
                            rootDispatch.confirm.openConfirmDialog({
                                showConfirmDialog: true,
                                title: i18n.t("common.fileDoesNotExist").toString(),
                                message: i18n.t("common.fileDoesNotExist").toString(),
                                links: [],
                                options: { buttonColor: "error", layoutColor: "error" },
                                buttonType: "ok"
                            })
                            router.push({ path: "/contractor/edit/" + state.contractor.id })
                        }
                        dispatch.loadContractorDocumentList();
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        saveLocation(context) {
            const { state } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/contractor/" + state.contractor.id + "/location/save/", state.contractor)
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data)
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        loadContractorsLocationList(context, payload: {
            searchContractorTypeList: string[];
        }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                const params = {
                    searchTypes: payload.searchContractorTypeList,
                };
                axiosInstance
                    .get("/api/contractor/location/list", { params })
                    .then((resp: AxiosResponse) => {
                        commit.setContractorsLocationList(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        loadContractorLocationDetails(context, payload: { id: number }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/contractor/" + payload.id + "/location")
                    .then((resp: AxiosResponse) => {
                        commit.setContractorsLocationDetails(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        async getContractorDetailsForSupervisor(context, payload: { contractorId: number, contractorType: string }) {
            const { commit } = _moduleActionContext(context)
            {
                return await new Promise((resolve, reject) => {
                    axiosInstance
                        .get("/api/contractor/getContractorDetailsForSupervisor/" + payload.contractorId)
                        .then((resp: AxiosResponse) => {
                            if (payload.contractorType == ContractorTypes.Client) {
                                commit.setContractorSenderDetailsForSupervisor(resp.data);
                            }
                            if (payload.contractorType == ContractorTypes.Carrier) {
                                commit.setContractorCarrierDetailsForSupervisor(resp.data);
                            }
                            if (payload.contractorType == ContractorTypes.Payer) {
                                commit.setContractorPayerDetailsForSupervisor(resp.data);
                            }
                            resolve(resp.data);
                        })
                        .catch((err: AxiosError) => {
                            if (payload.contractorType == ContractorTypes.Client) {
                                commit.setContractorSenderDetailsForSupervisor({ disableField: true } as ContractorDetailsForSupervisorRestModel);
                            }
                            if (payload.contractorType == ContractorTypes.Carrier) {
                                commit.setContractorCarrierDetailsForSupervisor({ disableField: true } as ContractorDetailsForSupervisorRestModel);
                            }
                            if (payload.contractorType == ContractorTypes.Payer) {
                                commit.setContractorPayerDetailsForSupervisor({ disableField: true } as ContractorDetailsForSupervisorRestModel);
                            }
                            reject(err);
                        })
                });
            }
        },
        async getContractorDetailsForSettlement(context, payload: { contractorId: number }) {
            const { commit } = _moduleActionContext(context)
            {
                return await new Promise((resolve, reject) => {
                    axiosInstance
                        .get("/api/contractor/getContractorDetailsForSettlement/" + payload.contractorId)
                        .then(async (resp: AxiosResponse) => {
                            commit.setContractorDetailsForSettlement(resp.data as ContractorDetailsForSettlementRestModel);
                            await resolve(resp.data);
                        })
                        .catch((err: AxiosError) => {
                            reject(err);
                        })
                });
            }
        },
        async checkIfContractorPolicyIsActive(context, payload: { contractorId: number, contractorPolicyTypeId: number, checkDate: string }) {
            const { commit } = _moduleActionContext(context)
            {
                return await new Promise((resolve, reject) => {
                    const params = {
                        contractorId: payload.contractorId,
                        policyTypeId: payload.contractorPolicyTypeId,
                        checkDate: payload.checkDate
                    };
                    axiosInstance
                        .get("/api/contractor/checkIfContractorPolicyIsActive", { params })
                        .then((resp: AxiosResponse) => {
                            commit.setCheckIfContractorPolicyIsActive(resp.data);
                            resolve(resp.data);
                        })
                        .catch((err: AxiosError) => reject(err));
                });
            }
        },
        async checkIfContractorHasExceededDebtLimit(context, payload: { contractorId: number, contractorType: string }) {
            const { commit } = _moduleActionContext(context)
            {
                return await new Promise((resolve, reject) => {
                    axiosInstance
                        .get("/api/contractor/checkIfContractorHasExceededDebtLimit/" + payload.contractorId)
                        .then((resp: AxiosResponse) => {
                            if (payload.contractorType == ContractorTypes.Client) {
                                commit.setCheckIfContractorClientHasExceededDebtLimit(resp.data);
                            }
                            if (payload.contractorType == ContractorTypes.Payer) {
                                commit.setCheckIfContractorPayerHasExceededDebtLimit(resp.data);
                            }

                            resolve(resp.data);
                        })
                        .catch((err: AxiosError) => reject(err));
                });
            }
        },
        async checkIfContractorTinExists(context, payload: { contractorTin: string, contractorId: number }): Promise<boolean> {
            const { commit } = _moduleActionContext(context)
            {
                return await new Promise((resolve, reject) => {
                    axiosInstance
                        .get("/api/contractor/checkIfContractorTinExists/" + payload.contractorId + "/" + payload.contractorTin)
                        .then((resp: AxiosResponse) => {
                            resolve(resp.data);
                            return resp.data;
                        })
                        .catch((err: AxiosError) => {
                            reject(err);
                            return true;
                        });
                });
            }
        },
        loadNoteList(context) {
            const { state, commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/contractor/" + state.contractor.id + "/note/list")
                    .then((resp: AxiosResponse) => {
                        commit.setNoteList(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        closeNoteDialog(context) {
            const { commit } = _moduleActionContext(context);
            commit.setShowNoteDialog(false);
        },
        saveNote(context) {
            const { state, commit, dispatch } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/contractor/" + state.contractor.id + "/note/save", state.note)
                    .then((resp: AxiosResponse) => {
                        commit.setShowNoteDialog(false);
                        dispatch.loadNoteList();
                        const response = resp.data;
                        resolve(resp.data);
                        return response;
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        loadNote(context, payload: { id: number }) {
            const { state, commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/contractor/" + state.contractor.id + "/note/" + payload.id)
                    .then((resp: AxiosResponse) => {
                        commit.setNote(resp.data);
                        commit.setShowNoteDialog(true);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        deleteNote(context, payload: { id: number }) {
            const { state, dispatch } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/contractor/" + state.contractor.id + "/note/delete/" + payload.id)
                    .then((resp: AxiosResponse) => {
                        dispatch.loadNoteList();
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        async getContractorMementoList(context, payload: { contractorId: number }) {
            const { commit } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/contractor/getContractorMementoList/" + payload.contractorId)
                    .then(async (resp: AxiosResponse) => {
                        commit.setContractorMementoListCount(resp.data.contractorMementosCount)
                        commit.setContractorMementoList(resp.data.contractorMementoList)
                        await resolve(resp.data);
                    })
                    .catch((error: AxiosError) => reject(error));
            })
        },
        async getContractorMementoPoint(context, payload: { mementoPoint: number }) {
            const { state, commit } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/contractor/getContractorMementoPoint/" + state.contractor.id + '/' + payload.mementoPoint)
                    .then(async (resp: AxiosResponse) => {
                        commit.setContractor(resp.data);
                        await resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            }
            )
        },
        generateKpiReport(context, payload: { selectedMonth: string }) {
            const { state, rootDispatch } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/contractor/generateKpiReport", { selectedMonth: payload.selectedMonth, contractorId: state.contractor.id }, {
                        responseType: "blob"
                    })
                    .then((resp: AxiosResponse) => {
                        const contentType = resp.headers['content-type'];
                        if (contentType.includes("application/json")) {
                            const reader = new FileReader();
                            reader.onload = () => {
                                const respJson = JSON.parse(reader.result as string);
                                rootDispatch.confirm.openConfirmDialog({
                                    showConfirmDialog: true,
                                    title: "Error during KPI report export",
                                    message: respJson.error,
                                    links: [],
                                    options: { buttonColor: "error", layoutColor: "error" },
                                    buttonType: "ok"
                                });
                                reject(new Error("test1"));
                            };
                            reader.readAsText(resp.data);
                        }
                        else {
                            saveFile(resp);
                        }

                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err))
            })
        },
    }
});

export default contractorModule;
const _moduleActionContext = (context: any) => moduleActionContext(context, contractorModule);