import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import TransportOrderRestModel from "../../models/rest/transport-order/transportOrderRestModel";
import TransportOrderDocumentRestModel from "../../models/rest/transport-order/cransportOrderDocumentRestModel";
import DocumentDialog from "./dialog/document.vue";
import { stringMaxLength } from "../../assets/validation/common";
import VehicleTypeRestModel from "../../models/rest/vehicle/vehicleTypeRestModel";
import { Roles } from "../../models/emums/roles";
import router from "../../router";

@Component({
    components: {
        "document-dialog": DocumentDialog
    }
})
export default class TransportOrder extends Vue {
    validation = {
        senderEmail: [stringMaxLength(this.$i18n, this.$t("transportOrder.email"), 150)],
        trackingUrl: [stringMaxLength(this.$i18n, this.$t("transportOrder.trackingURL"), 255)],
        trackingLoginAccessData: [stringMaxLength(this.$i18n, this.$t("transportOrder.loginAccessData"), 255)],
        loadingAddress: [stringMaxLength(this.$i18n, this.$t("transportOrder.address"), 255), (value: string) => !!value || this.$t("common.validationErrorRequired")],
        loadingContactPerson: [stringMaxLength(this.$i18n, this.$t("transportOrder.contactPerson"), 255)],
        loadingPhone: [stringMaxLength(this.$i18n, this.$t("transportOrder.phone"), 255)],
        unloadingAddress: [stringMaxLength(this.$i18n, this.$t("transportOrder.address"), 255), (value: string) => !!value || this.$t("common.validationErrorRequired")],
        unloadingContactPerson: [stringMaxLength(this.$i18n, this.$t("transportOrder.contactPerson"), 255)],
        unloadingPhone: [stringMaxLength(this.$i18n, this.$t("transportOrder.phone"), 255)],
        outboundCustomsClearance: [stringMaxLength(this.$i18n, this.$t("transportOrder.outboundCustomsClearance"), 255)],
        inboundCustomsClearance: [stringMaxLength(this.$i18n, this.$t("transportOrder.inboundCustomsClearance"), 255)],
        goodsValue: [stringMaxLength(this.$i18n, this.$t("transportOrder.goodsValueForCargoInsurance"), 255)],
        referenceNumber: [stringMaxLength(this.$i18n, this.$t("transportOrder.referenceNumber"), 255)],
        billNumber: [
            stringMaxLength(this.$i18n, this.$t("transportOrder.billNumber"), 255),
            (value: string) => {
                if (this.transportOrder.billNumberIsRequired && !value) {
                    return this.$t("common.validationErrorRequired");
                }
                return true;
            }
        ],
        commodity: [stringMaxLength(this.$i18n, this.$t("transportOrder.commodity"), 255)],
        dimensions: [stringMaxLength(this.$i18n, this.$t("transportOrder.dimensions"), 255)],
        quantity: [stringMaxLength(this.$i18n, this.$t("transportOrder.quantity"), 255)],
        totalWeight: [stringMaxLength(this.$i18n, this.$t("transportOrder.totalWeight"), 255)],
        dangerousGoods: [stringMaxLength(this.$i18n, this.$t("transportOrder.dangerousGoods"), 255)],
        netPrice: [stringMaxLength(this.$i18n, this.$t("transportOrder.netPrice"), 255)],
        goodsAreSubjectToCustomClearance: [(value: boolean) => (value != null || this.$t("common.validationErrorRequired"))],
        goodsAreSubjectToAdditionalDeclarations: [(value: boolean) => (value != null || this.$t("common.validationErrorRequired"))],
        goodsOfStrategicUse: [(value: boolean) => (value != null || this.$t("common.validationErrorRequired"))],
        dualUseItems: [(value: boolean) => (value != null || this.$t("common.validationErrorRequired"))],
        additionalDeclarationsComments: [((value: string) => !!value || !this.transportOrder.goodsAreSubjectToAdditionalDeclarations || this.$t("common.validationErrorRequired")), stringMaxLength(this.$i18n, this.$t("transportOrder.additionalDeclarationsComments"), 255)],
    };

    commodity = [
        { text: "standard pallet - 120x80 cm", value: "standard pallet - 120x80 cm" },
        { text: "industrial pallet - 120x100 cm", value: "industrial pallet - 120x100 cm" },
        { text: "gitterbox - 124x83 cm", value: "gitterbox - 124x83 cm" },
        { text: "koffer - case", value: "koffer - case" },
        { text: "other", value: "other" },
    ]

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get loadingAddressesFound() {
        return this.$store.direct.state.transportOrder.loadingAddressesFound;
    }

    get unloadingAddressesFound() {
        return this.$store.direct.state.transportOrder.unloadingAddressesFound;
    }

    loadingAddressSearch = "";
    unloadingAddressSearch = "";

    @Watch("loadingAddressSearch")
    loadingAddressSearchLoadItems(val: string) {
        this.$store.direct.dispatch.transportOrder.searchAddress({ searchText: val, searchTextType: "loadingAddress" });
    }

    @Watch("unloadingAddressSearch")
    unloadingAddressSearchLoadItems(val: string) {
        this.$store.direct.dispatch.transportOrder.searchAddress({ searchText: val, searchTextType: "unloadingAddress" });
    }

    documentListHeaders = [
        { text: this.$t("contractor.file"), align: "start", value: "name" },
        { text: this.$t("transportOrder.proofOfDelivery"), align: "start", value: "isProofOfDelivery" },
        { text: this.$t("common.createdAt"), align: "start", value: "createdAt" },
        { text: this.$t("common.createdBy"), align: "start", value: "createdBy" }
    ];

    get vehicleType(): VehicleTypeRestModel[] {
        return this.$store.direct.state.common.vehicleTypeList;
    }

    get documentList(): TransportOrderDocumentRestModel[] {
        return this.$store.direct.state.transportOrder.documentList;
    }

    get transportOrderCardTitle(): string {
        return this.$store.direct.state.transportOrder.transportOrderCardTitle;
    }

    get currentUserRoles() {
        return this.$store.direct.state.auth.userRoles;
    }

    get showAlert(): boolean {
        return this.$store.direct.state.transportOrder.transportOrder.showTransportOrderMissContractorAlert;
    }

    get currentUserContractorId(): number {
        return this.$store.direct.state.auth.currentUserContractorId;
    }

    get transportOrder(): TransportOrderRestModel {
        return this.$store.direct.state.transportOrder.transportOrder
    }

    set transportOrder(val: TransportOrderRestModel) {
        this.transportOrder = val;
    }

    created() {
        this.$store.direct.dispatch.common.loadVehicleTypeList();
    }

    showTrackingDataFields(): boolean {
        if (this.currentUserRoles.includes(Roles.Disponent) || this.currentUserRoles.includes(Roles.DisponentAdmin) || this.currentUserRoles.includes(Roles.Admin)) {
            return true;
        }
        else {
            return false
        }
    }

    isPathNameShowTransportOrder(): boolean {
        if (this.$route.name == "Show transport order") {
            this.$store.direct.commit.transportOrder.setTransportOrderCardTitle(`${this.$t("transportOrder.showTransportOrder")}`)
            return true;
        }
        else {
            this.$store.direct.commit.transportOrder.setTransportOrderCardTitle(`${this.$t("transportOrder.addTransportOrder")}`)
            return false;
        }
    }

    async goBack(): Promise<void> {
        await router.push({ name: "Transport order list" });
    }

    async saveClick() {
        const valid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
        if (valid) {
            await this.$store.direct.dispatch.transportOrder.saveTransportOrder();
        }
    }

    async documentCreateClick() {
        await this.$store.direct.dispatch.transportOrder.createDocument();
    }

    async documentDownloadClick(model: TransportOrderRestModel) {
        await this.$store.direct.dispatch.transportOrder.downloadDocument({ id: model.id });
    }

    destroyed() {
        this.$store.direct.commit.transportOrder.setTransportOrder({} as TransportOrderRestModel)
    }

    async saveTrackingData() {
        this.$store.direct.dispatch.transportOrder.saveTrackingData({ transportOrderId: this.transportOrder.id, trackingUrl: this.transportOrder.trackingUrl, trackingLoginAccessData: this.transportOrder.trackingLoginAccessData })
    }
}
